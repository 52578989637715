<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Job Order Information</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-1"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Information" active>
              <b-row>
                <b-col cols="12" :md="openSection ? '9' : '12'">
                  <b-row>
                    <b-col class="text-right">
                      <b-form-group>
                        <b-button
                          :disabled="isLoading"
                          type="button"
                          class="mr-1"
                          variant="outline-primary"
                          @click="onDuplicate"
                        >
                          Duplicate
                        </b-button>
                        <b-button
                          type="button"
                          :class="isJobNew && getRole ? 'mr-1' : ''"
                          variant="outline-danger"
                          @click="returnToPage"
                        >
                          Back
                        </b-button>
                        <b-button
                          v-if="isJobNew && getRole"
                          @click="onEdit"
                          type="button"
                          variant="success"
                        >
                          Edit
                        </b-button>
                      </b-form-group>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <small v-if="+$route.query.id">
                          last edited: {{ formData.preview_updated_at }}
                        </small>
                        <h2>
                          Job Order Information -
                          <strong>
                            {{ formData.preview_job_status }}
                            ({{ formData.job_name }})
                          </strong>
                        </h2>

                        <b-button-group size="sm" v-if="getRole">
                          <b-button
                            @click="onUpdateJobStatus('0')"
                            variant="primary"
                          >
                            New
                          </b-button>
                          <b-button
                            @click="onUpdateJobStatus('1')"
                            variant="info"
                          >
                            Accepted
                          </b-button>
                          <b-button
                            @click="onUpdateJobStatus('2')"
                            variant="warning"
                          >
                            Work in progress
                          </b-button>
                          <b-button
                            @click="onUpdateJobStatus('3')"
                            variant="danger"
                          >
                            Paused
                          </b-button>
                          <b-button
                            @click="onUpdateJobStatus('4')"
                            variant="success"
                          >
                            Done
                          </b-button>
                          <b-button
                            @click="onUpdateJobStatus('5')"
                            variant="secondary"
                          >
                            Completed
                          </b-button>
                        </b-button-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="text-right font-weight-bold">
                      <b-form-group>
                        <!-- <div class="">
                          Service Request No.: (
                          <span
                            class="cursor-pointer"
                            style="color: #007bff"
                            @click="onViewServiceRequest()"
                          >
                            {{ formData.ticket_parent_no }}
                          </span>
                          )
                        </div> -->
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Ticket No." label-for="v-ticket-no">
                        <b-form-input
                          disabled
                          v-model="formData.ticket_no"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="5">
                      <b-form-group label="Company" label-for="v-company">
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Contract No."
                        label-for="v-contract_refnum"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.contract_refnum"
                          id="v-contract_refnum"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Po No." label-for="v-po_no">
                        <b-form-input
                          v-model="formData.po_no"
                          id="v-po_no"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Name" label-for="v-ticket-no">
                        <b-form-input
                          v-model="formData.job_name"
                          disabled
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Type" label-for="v-company">
                        <b-form-input
                          v-model="formData.job_type"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Status" label-for="v-company">
                        <!-- <b-form-input
                          v-model="formData.preview_job_status"
                          id="v-company"
                          disabled
                        /> -->

                        <b-select
                          disabled
                          v-model="formData.job_status"
                          id="v-job_status"
                          :options="[
                            { text: 'New', value: '0' },
                            { text: 'Accepted', value: '1' },
                            { text: 'Work in progress', value: '2' },
                            { text: 'Paused', value: '3' },
                            { text: 'Done', value: '4' },
                            { text: 'Completed', value: '5' },
                          ]"
                        ></b-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Recipients" label-for="v-company">
                        <b-form-input
                          v-model="formData.email"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Report Date"
                        label-for="v-report_date"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.preview_report_date"
                          id="v-report_date"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Job Scheduled Expected Arrival"
                        label-for="v-job_eta"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.preview_job_eta"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Job Scheduled Expected Departure"
                        label-for="v-job_eta"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.preview_job_etd"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Chargeable Amount"
                        label-for="v-chargeable_amount"
                      >
                        <b-form-input
                          disabled
                          class="text-right"
                          v-model="formData.chargeable_amount"
                          id="v-chargeable_amount"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="New Chargeable Amount"
                        label-for="v-post_amount"
                      >
                        <b-form-input
                          disabled
                          class="text-right"
                          v-model="formData.post_amount"
                          id="v-post_amount"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Job Started" label-for="v-job_ata">
                        <b-form-input
                          disabled
                          id="v-job_ata"
                          v-model="formData.preview_job_ata"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Job Ended" label-for="v-job_atd">
                        <b-form-input
                          disabled
                          id="v-job_atd"
                          v-model="formData.preview_job_atd"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Assignment Information</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Primary Technician"
                        label-for="v-sales-person"
                      >
                        <v-select
                          disabled
                          v-model.number="formData.job_assigned_to_1"
                          :options="employeeItems"
                          :reduce="(employeeItems) => +employeeItems.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Secondary Technician"
                        label-for="v-sales-person"
                      >
                        <v-select
                          disabled
                          v-model.number="formData.job_assigned_to_2"
                          :options="employeeItems"
                          :reduce="(employeeItems) => +employeeItems.value"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Problem Description"
                        label-for="v-problem_description"
                      >
                        <b-form-textarea
                          disabled
                          id="v-problem_description"
                          v-model="formData.problem_description"
                          rows="4"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Job Description"
                        label-for="v-job_description"
                      >
                        <quill-editor
                          disabled
                          ref="quillEditorRef"
                          v-model="formData.job_instructions"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Job Site</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingForm = !isHidingForm"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingForm,
                          arrow_up: !isHidingForm,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingForm">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <GmapMap
                            :center="center"
                            :zoom="13"
                            map-type-id="terrain"
                            style="width: 100%; height: 400px"
                          >
                            <GmapMarker
                              :title="`${formData.addr1} ${formData.addr2}`"
                              :position="marker"
                              @click="openMap()"
                            />
                          </GmapMap>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Site/Branch/Outlet Name"
                          label-for="v-site_id"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.site_id"
                            id="v-site_id"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group
                          label="Service Type"
                          label-for="v-service_type"
                        >
                          <v-select
                            disabled
                            id="v-service_type"
                            v-model="formData.contract_type"
                            :options="[
                              'project',
                              'installation',
                              'maintenance',
                              'chargeable',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group
                          label="Service Location"
                          label-for="v-service_location"
                        >
                          <b-form-input
                            disabled
                            id="v-service_location"
                            v-model="serviceLocation"
                            class=""
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Building/Block/House No. and Street Name"
                          label-for="v-addr1"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.addr1"
                            id="v-addr1"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Unit No. and Building Name"
                          label-for="v-addr2"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.addr2"
                            id="v-addr2"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Country" label-for="v-country">
                          <b-form-input
                            disabled
                            v-model="formData.country"
                            id="v-country"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="Postal Code" label-for="v-postal">
                          <b-form-input
                            disabled
                            v-model="formData.postal"
                            id="v-postal"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <ModalMap
                      :showMap="isShowingMap"
                      @onChangePlace="(v) => getPlace(v)"
                    />
                  </span>

                  <b-row>
                    <b-col cols="6">
                      <h2>Contact Information</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingFormContact = !isHidingFormContact"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingFormContact,
                          arrow_up: !isHidingFormContact,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingFormContact">
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Contact Person" label-for="v-poc">
                          <b-form-input
                            disabled
                            v-model="formData.poc"
                            id="v-poc"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="Mobile" label-for="v-mobile">
                          <b-form-input
                            disabled
                            v-model="formData.mobile"
                            id="v-mobile"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </span>

                  <b-row>
                    <b-col>
                      <h2>Proposed Spares Needed</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderSpares
                          isDisabled
                          :data-source="dataSourceSpares"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Item(s) for Servicing</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderDetails
                          isDisabled
                          :data-source="dataSourceDetails"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Customer's (Problem) Photos</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderImages
                          isDisabled
                          :data-source="dataSourceImages"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Checklists</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Technician Checklists"
                        label-for="v-technician"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.technician_checklist_id"
                          id="v-technician"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Customer Checklists"
                        label-for="v-customer"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.customer_checklist_id"
                          id="v-customer"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Other Information</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingFormRemarks = !isHidingFormRemarks"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingFormRemarks,
                          arrow_up: !isHidingFormRemarks,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingFormRemarks"
                    ><b-row>
                      <b-col cols="12">
                        <b-form-group label="GST" label-for="v-gst">
                          <b-form-input
                            disabled
                            v-model="formData.gst"
                            id="v-gst"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Instructions/Remarks"
                          label-for="v-remarks"
                        >
                          <b-form-textarea
                            disabled
                            v-model="formData.remarks"
                            id="v-remarks"
                            rows="4"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </span>
                </b-col>
              </b-row>
            </b-tab>
            <span v-if="$route.query.id">
              <b-tab @click="loadTimeline()" title="Timeline">
                <JobOrderTimeline
                  @on-submit-timeline="(timeline) => onSubmitTimeline(timeline)"
                  :data-source="dataSourceTimeline"
                />
              </b-tab>
              <b-tab title="Previous Jobs">
                <JobOrderPreviousJobs :data-source="dataSourcePreviousJobs" />
              </b-tab>
              <b-tab title="Notes">
                <Notes ref="notesRef" module="JO" :header-id="getFormDataID" />
              </b-tab>
              <b-tab title="Documents">
                <Documents
                  ref="documentsRef"
                  module="JO"
                  :header-id="getFormDataID"
                />
              </b-tab>
              <b-tab title="Logs">
                <Logs ref="logsRef" module="joborder" />
              </b-tab>
              <b-tab title="Comments">
                <Comments ref="commentsRef" module="joborder" />
              </b-tab>
            </span>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-row>
          <b-col>
            <b-card>
              <b-tabs fill>
                <b-tab active>
                  <template #title>
                    <small>Comments</small>
                  </template>
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Logs</small>
                  </template>
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Approval</small>
                  </template>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import jobOrderApi from '@api/joborder_headers';
  import usersApi from '@api/users';
  import timelineHeadersApi from '@api/timeline_headers';
  import timelinesApi from '@api/timelines';
  import { queryParameters } from '@/schema';
  import JobOrderSpares from './components/JobOrderSpares';
  import JobOrderDetails from './components/JobOrderDetails';
  import JobOrderImages from './components/JobOrderImages';
  import JobOrderTimeline from './components/JobOrderTimeline';
  import JobOrderPreviousJobs from './components/JobOrderPreviousJobs';
  import JobOrderLogs from './components/JobOrderLogs';
  import JobOrderComments from './components/JobOrderComments';

  import { getUserData } from '@/auth/utils';

  export default {
    name: 'JobOrderManage',
    components: {
      JobOrderSpares,
      JobOrderDetails,
      JobOrderImages,
      JobOrderTimeline,
      JobOrderPreviousJobs,
      JobOrderLogs,
      JobOrderComments,
    },
    data: () => ({
      center: { lat: 1.29027, lng: 103.851959 },
      marker: { lat: 1.29027, lng: 103.851959 },

      success: false,
      openSection: false,
      errors: {},
      isJobNew: true,
      activeTab: 0,
      isShowingMap: false,
      isHidingForm: false,
      isHidingFormContact: false,
      isHidingFormRemarks: false,
      isLoading: false,
      formData: {
        id: null,

        preview_report_date: null,
        preview_job_ata: null,
        preview_job_atd: null,
      },
      employeeItems: [],
      dataSourceSpares: [],
      dataSourceDetails: [],
      dataSourceImages: [],
      dataSourceTimeline: [],
      dataSourcePreviousJobs: [],
      timelineHeaderId: 0,

      mapImage: `https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=640x460&maptype=roadmap&key=${process.env.VUE_APP_ROOT_GMAP_KEY}&markers=color:red%7C`,
      mapDest: 'https://www.google.com/maps/dir/?api=1&destination=',
    }),
    watch: {
      section(v) {
        this.openSection = v;
      },
      isJobNew(v) {
        const { job_status } = this.formData;
        return ['0', '1'].includes(job_status);
      },
      activeTab(index) {
        if (index === 3) {
          this.$refs.notesRef.loadNotes();
        }

        if (index === 4) {
          this.$refs.documentsRef.loadDocuments();
        }

        if (index === 5) {
          this.$refs.logsRef.loadLogs();
        }

        if (index === 6) {
          this.$refs.commentsRef.loadTimelineHeader();
        }
      },
    },
    computed: {
      serviceLocation: {
        get() {
          const { service_location } = this.formData;

          return service_location === 'O' ? 'On-Site' : 'Remote';
        },
      },
      getFormDataID: {
        get() {
          return parseInt(this.formData.id);
        },
      },
      getRole: {
        get() {
          const userData = getUserData();
          const { role } = userData;

          return role === 'admin';
        },
      },
    },
    mounted() {
      if (+this.$route.query.id) {
        this.loadJobRequest();
      }
    },
    methods: {
      loadAvailableTechnicians() {
        this.employeeItems = [{ label: 'N/A', value: 0 }];

        const replace = (data) => {
          let date = data.replace('T', ' ');
          date += ':00.000 +0800';

          return date;
        };

        const job_eta = replace(
          this.inputDateTimeFromUnix(this.formData.job_eta)
        );
        const job_etd = replace(
          this.inputDateTimeFromUnix(this.formData.job_etd)
        );

        const params = {
          filterExt: `from_to=${job_eta}|${job_etd}`,
        };

        usersApi
          .getAvailableTechnicians(params)
          .then(({ data }) => {
            for (const item of data) {
              const { id, first_name, last_name, username, has_jobs } = item;

              let fullName = first_name || username;
              if (first_name && last_name) {
                fullName += ` ${last_name}`;
              }

              this.employeeItems.push({
                label: fullName,
                value: parseInt(id),
                has_jobs,
                item,
              });
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadJobRequest() {
        jobOrderApi
          .get(this.$route.query.id)
          .then(({ data }) => {
            if (data) {
              const {
                job_status,
                updated_at,
                report_date,
                job_eta,
                job_etd,
                job_ata,
                job_atd,
                longitude: lng,
                latitude: lat,
                joborder_spares,
                joborder_details,
                joborder_images,
                joborder_previous_jobs,
                post_amount,
                chargeable_amount,
              } = data;

              this.center = { lat, lng };
              this.marker = { lat, lng };

              const displayToLocale = (date) => {
                if (date) {
                  return this.displayDateTimeFromUnix(date);
                }
              };

              this.isJobNew = ['0', '1'].includes(job_status);

              data.preview_job_status = this.getJobStatus(job_status);
              data.preview_updated_at = displayToLocale(updated_at);
              data.preview_report_date = displayToLocale(report_date);
              data.preview_job_eta = displayToLocale(job_eta);
              data.preview_job_etd = displayToLocale(job_etd);
              data.preview_job_ata = displayToLocale(job_ata);
              data.preview_job_atd = displayToLocale(job_atd);

              const getDetails = (details) => {
                if (details && details.data) {
                  return details.data;
                }

                return [];
              };

              this.dataSourceSpares = getDetails(joborder_spares);
              this.dataSourceDetails = getDetails(joborder_details);
              this.dataSourceImages = getDetails(joborder_images);
              this.dataSourcePreviousJobs = getDetails(joborder_previous_jobs);

              this.mapImage += `${data.addr1} ${data.addr2}`;
              this.mapDest += `${data.latitude},${data.longitude}`;

              data.hrs = Math.floor(data.job_minutes / 60);
              data.mins = data.job_minutes % 60;

              data.post_amount = this.moneyFormat(post_amount).replace('$', '');
              data.chargeable_amount = this.moneyFormat(
                chargeable_amount
              ).replace('$', '');

              this.formData = data;
              this.loadAvailableTechnicians();
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            if (+this.$route.query.activeTab) {
              this.loadTimeline();
              this.activeTab = +this.$route.query.activeTab;
            }
          });
      },
      loadTimeline() {
        timelineHeadersApi
          .get(this.$route.query.id, 'timeline')
          .then(({ data }) => {
            if (data.timeline) {
              const { data: timelines } = data.timeline;
              this.timelineHeaderId = parseInt(data.id);
              this.dataSourceTimeline = timelines;
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      },
      onEdit() {
        const url = `/pages/services-tools/job-order/manage?id=${this.$route.query.id}`;
        this.$router.push(url);
      },
      onUpdateJobStatus(v) {
        const formData = {
          id: parseInt(this.$route.query.id),
          jobstatus: v,
        };

        jobOrderApi
          .updatejobstatus(formData)
          .then(({ data }) => {
            if (data) {
              if (v === '9') {
                this.loadJobRequest();
              }

              const unix = new Date() / 1000;
              const displayDate = this.displayDateTimeFromUnix(unix);

              this.formData.preview_job_status = this.getJobStatus(v);

              if (v === '2') {
                this.formData.preview_job_ata = displayDate;
              }

              if (v === '9') {
                this.formData.preview_job_atd = displayDate;
              }

              this.isJobNew = ['0', '1'].includes(v);
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      returnToPage() {
        this.$router.push(`/pages/services-tools/job-order`);
      },
      getPlace(place) {
        //
      },
      openMap() {
        window.open(this.mapDest, '_blank');
      },
      onSubmitTimeline(item) {
        item.header_id = this.timelineHeaderId;
        item.ticket_no = this.formData.ticket_no;
        item.refnum = this.formData.refnum;

        timelinesApi
          .add(item)
          .then((data) => {
            if (data) {
              this.loadTimeline();
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      onViewServiceRequest() {
        const url = `/pages/services-tools/service-request/view?id=${this.formData.request_id}`;
        window.open(url, '_blank');
      },
      onDuplicate() {
        this.isLoading = true;
        const formData = { from_id: this.formData.id };

        jobOrderApi
          .duplicate(formData)
          .then(({ data }) => {
            if (data) {
              this.isLoading = false;
              const url = `/pages/services-tools/job-order/view?id=${data.id}`;
              window.location.replace(url);
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .pin:hover {
    cursor: pointer;
  }

  .isDisplaying.arrow_down {
    transform: rotate(180deg);
    transition: transform 0.25s linear;
  }

  .isDisplaying.arrow_up {
    transform: rotate(360deg);
    transition: transform 0.25s linear;
  }
</style>
